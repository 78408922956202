<template>
    <div>
        <v-text-field
            v-model="search"
            label="Поиск"
            height="44"
            hide-details
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            class="mb-5"
            @change="getList"
        >
        </v-text-field>
        <v-btn color="primary" @click="addEmployee">Создать сотрудника</v-btn>
        <v-data-table
            :headers="headers"
            :items="items.results"
            disable-sort
            :options.sync="options"
            :server-items-length="items.count"
        >
            <template v-slot:[`item.auto`]="{ item }">
                <v-btn small :color="item.auto ? 'success' : 'error'">{{ item.auto ? 'Активировать' : 'Деактивировать' }}</v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click.stop="onDelete(item)">
                    <img src="@/assets/images/ui/delete.svg" alt="">
                </v-btn>
            </template>
        </v-data-table>

        <!-- Agent Info Dialog -->
        <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' @onSubmit='onSubmit'>
            <v-form
                ref="form"
                lazy-validation
            >
                <input-content
                    :items="inputItems"
                    :model="model"
                    :dialog='dialog'
                />
            </v-form>
        </vue-dialog>
        <!-- END Agent Info Dialog -->
        <!-- Delete Agent Dialog -->
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <div class="pt-10 pb-10 pr-16 pl-16">
              <v-row class="mb-5">
                <v-col cols="12" md="12">
                  <h2 class="primary--text pt-1 ml-n3 mb-3">Удалить сотрудника</h2>
                </v-col>
              </v-row>
              <v-row class="mb-5">
                <p>Вы уверены что хотите удалить сотрудника?</p>
              </v-row>
              <v-row>
                <v-btn @click="deleteAgent" color="error" class="w-full mb-3" large
                  >Удалить</v-btn
                >
                <v-btn
                  @click="
                    {
                      {
                        dialogDelete = false;
                      }
                    }
                  "
                  color="primary"
                  class="w-full"
                  outlined
                  large
                  >Отменить</v-btn
                >
              </v-row>
            </div>
          </v-card>
        </v-dialog>


    </div>
</template>

<script>
import vueAddButton from '@/components/ui/vueAddButton.vue'
import VueDialog from '@/components/ui/vueDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import pagination from "@/utils/pagination.js"
import { employeesService } from "@/services/employes.service.js"
export default {
    mixins: [pagination],
    components: {
        vueAddButton,
        InputContent,
        VueDialog
    },
    data: () => ({
        search: null, 
        items: {},
        dialogDelete: false, 
        dialogAgentInfo: false,
        dialog: false,  
        selectedAgent: {}, 
        model: {},
        inputItems: [
        {
            type: 'text',
            label: 'Имя',
            model: 'first_name',
            rules: 'required',
            cols: 6
        },
        {
            type: 'text',
            label: 'Фамилия',
            model: 'last_name',
            rules: 'required',
            cols: 6
        },
        {
            type: 'text',
            label: 'Отчество',
            model: 'middle_name',
            rules: 'required',
            cols: 6
        },
        {
            type: 'text',
            label: 'Email',
            model: 'email',
            rules: 'email',
            cols: 6
        },
        {
            type: 'text',
            label: 'Пароль',
            model: 'password',
            rules: 'required',
            cols: 6
        },
        {
            type: 'text',
            label: 'Повторите пароль',
            model: 'password2',
            rules: 'required',
            cols: 6
        },
        {
            type: 'select',
            label: 'Роль',
            model: 'role_type',
            multiple: false,
            getItems: 'getTourOperators',
            rules: 'required',
            hardcoded: true, 
            cols: 12,
            loading: false, 
        },
        ],
        headers: [
            { text: 'ФИО', value: 'full_name' },
            // { text: 'Телефон', value: 'phone_number' },
            { text: 'Email', value: 'email' },
            { text: '', value: 'actions' },
        ],
    }),
    computed: {
        dialogOptions() {
            return {
                width: '711',
                title: this.model.id ? 'Изменить сотрудника' : 'Создать сотрудника',
                button: 'Сохранить'
            }
        }
    },
    methods: {
        async onSubmit() {
            if (!this.$refs.form.validate()) return
            if(this.model.password != this.model.password2) {
                this.$store.commit("ui/SET_NOTIFICATION", {
                    show: true,
                    message: "Пароли должны быть одинаковыми!",
                    color: "error",
                });
                return
            }
            await employeesService.createEmployee(this.model)
            this.getList()
            this.$store.commit("ui/SET_NOTIFICATION", {
                show: true,
                message: "Успешно создано!",
                color: "success",
            });
            this.closeDialog()
        },
        closeDialog() {
            this.model = {}
            this.user = {}
            this.$refs.form.reset()
            this.dialog = false
        },
        async getList(){
          let params = {
              page: this.options.page, 
              size: this.options.itemsPerPage,
              search: this.search
          }
          let res = await employeesService.getEmployees(params)
          this.items = res
        },
        onEdit(item) {
            this.selectedAgent = this.$copy(item)
            this.dialogAgentInfo = true
        },
        onDelete(item) {
            this.selectedAgent = this.$copy(item)
            this.dialogDelete = true
        },
        async deleteAgent(){
            await employeesService.deleteEmployee(this.selectedAgent.id)
            this.$store.commit("ui/SET_NOTIFICATION", {
                show: true,
                message: "Успешно удалено!",
                color: "success",
            });
            this.getList()
            this.selectedAgent = {}
            this.dialogDelete = false
        },
        addEmployee(){
            this.dialog = true
            this.model = {}
        }
    }
}
</script>

<style lang="scss" scoped>

</style>