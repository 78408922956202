var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"Поиск","height":"44","hide-details":"","outlined":"","dense":"","prepend-inner-icon":"mdi-magnify"},on:{"change":_vm.getList},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addEmployee}},[_vm._v("Создать сотрудника")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.results,"disable-sort":"","options":_vm.options,"server-items-length":_vm.items.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.auto",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":item.auto ? 'success' : 'error'}},[_vm._v(_vm._s(item.auto ? 'Активировать' : 'Деактивировать'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c('img',{attrs:{"src":require("@/assets/images/ui/delete.svg"),"alt":""}})])]}}],null,true)}),_c('vue-dialog',{attrs:{"model":_vm.dialog,"options":_vm.dialogOptions},on:{"closeDialog":_vm.closeDialog,"onSubmit":_vm.onSubmit}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('input-content',{attrs:{"items":_vm.inputItems,"model":_vm.model,"dialog":_vm.dialog}})],1)],1),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('div',{staticClass:"pt-10 pb-10 pr-16 pl-16"},[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h2',{staticClass:"primary--text pt-1 ml-n3 mb-3"},[_vm._v("Удалить сотрудника")])])],1),_c('v-row',{staticClass:"mb-5"},[_c('p',[_vm._v("Вы уверены что хотите удалить сотрудника?")])]),_c('v-row',[_c('v-btn',{staticClass:"w-full mb-3",attrs:{"color":"error","large":""},on:{"click":_vm.deleteAgent}},[_vm._v("Удалить")]),_c('v-btn',{staticClass:"w-full",attrs:{"color":"primary","outlined":"","large":""},on:{"click":function($event){{
                  {
                    _vm.dialogDelete = false;
                  }
                }}}},[_vm._v("Отменить")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }