import axios from "axios";
const url = '/employees/'

export const employeesService = {
    // мои сотрудники
    async getEmployees(params) {
        return (await axios.get(`${url}`, { params })).data
    },
    async getEmployee(id) {
        return (await axios.get(`${url}${id}/`)).data
    },
    async createEmployee(body){
        return (await axios.post(`${url}`, body)).data
    },
    async deleteEmployee(id) {
        return (await axios.delete(`${url}${id}/`)).data
    },
}